import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const ImageText = loadable(() => import("./modules/imageText"));
const Bubbles = loadable(() => import("./modules/bubbles"));
const FeatureTouchpoint = loadable(() => import("./modules/featureTouchpoint"));
const NewsTouchpoint = loadable(() => import("./modules/newsTouchpoint"));
const ImageBanner = loadable(() => import("./modules/imageBanner"));
const HalfImageBanner = loadable(() => import("./modules/halfImageBanner"));
const VideoText = loadable(() => import("./modules/videoText"));
const ProofPointCarousel = loadable(() =>
  import("./modules/proofPointCarousel")
);
const CardGrid = loadable(() => import("./modules/cardGrid"));
const SimpleImage = loadable(() => import("./modules/simpleImage"));
const ProductsCarousel = loadable(() => import("./modules/productsCarousel"));
const Timeline = loadable(() => import("./modules/timeline"));
const AccordionSet = loadable(() => import("./modules/accordionSet"));
const ColumnText = loadable(() => import("./modules/columnText"));
const ImageGrid = loadable(() => import("./modules/imageGrid"));
const BlogPortal = loadable(() => import("./modules/blogPortal"));
const Map = loadable(() => import("./modules/map"));
const FilteredMap = loadable(() => import("./modules/filteredMap"));
const ServiceBanner = loadable(() => import("./modules/serviceBanner"));
const TextBlock = loadable(() => import("./modules/textBlock"));
const AdvancedImageText = loadable(() => import("./modules/advancedImageText"));
const AdvancedVideoText = loadable(() => import("./modules/advancedVideoText"));
const IFrame = loadable(() => import("./modules/iFrame"));

const ModuleZone = ({ modules }) => {
  const component = modules.map((item, i) => {
    switch (item._type) {
      case "imageText":
        return <ImageText key={i} {...item} />;
      case "statsBubbles":
        return <Bubbles key={i} {...item} />;
      case "featureTouchpoint":
        return <FeatureTouchpoint key={i} {...item} />;
      case "newsTouchpoint":
        return <NewsTouchpoint key={i} {...item} />;
      case "imageBanner":
        return <ImageBanner key={i} {...item} />;
      case "halfImageBanner":
        return <HalfImageBanner key={i} {...item} />;
      case "videoText":
        return <VideoText key={i} {...item} />;
      case "proofPointCarousel":
        return <ProofPointCarousel key={i} {...item} />;
      case "cardGrid":
        return <CardGrid key={i} {...item} />;
      case "productServiceCarousel":
        return <ProductsCarousel key={i} {...item} />;
      case "timeline":
        return <Timeline key={i} {...item} />;
      case "accordionSet":
        return <AccordionSet key={i} {...item} />;
      case "singleColText":
        return <ColumnText key={i} {...item} />;
      case "imageGrid":
        return <ImageGrid key={i} {...item} />;
      case "blogPortal":
        return <BlogPortal key={i} {...item} />;
      case "map":
        return <Map key={i} {...item} />;
      case "filteredMap":
        return <FilteredMap key={i} {...item} />;
      case "serviceBanner":
        return <ServiceBanner key={i} {...item} />;
      case "textBlock":
        return <TextBlock key={i} {...item} />;
      case "advancedImageText":
        return <AdvancedImageText key={i} {...item} />;
      case "advancedVideoText":
        return <AdvancedVideoText key={i} {...item} />;
      case "iFrame":
        return <IFrame key={i} {...item} />;
      case "simpleImage":
        return <SimpleImage key={i} {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
